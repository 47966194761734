import { APP_CONFIG, PlatformType, UserType, } from '../types';
export const initialQuestionnaireState = {
    cachedQueryAnswers: {
        custom: {
            many: {},
            single: {},
        },
        normal: [],
    },
    current: {
        answers: [],
        computed_values: [],
        event_id: null,
        id: '',
        meta: {
            client_version: process.env.VERSION,
            platform: PlatformType.WEB,
            questionnaire_spec_version: '',
            session_id: '',
        },
        original_submission_id: '',
        questionnaire_id: '',
        state: {
            answers: [],
            computed_values: [],
            exit_action_queue_history: [],
            journey: [],
        },
        submitted_at: new Date().toISOString(),
        submitted_by: '',
        submitted_for: '',
    },
    partialSubmission: null,
    questionnaire: {
        cached_queries: [],
        core_media: [],
        core_texts: {
            id: '',
            nav_back: '',
            nav_continue: '',
            nav_start: '',
            nav_submit: '',
            quit_prompt_cancel: '',
            quit_prompt_content: '',
            quit_prompt_quit: '',
            quit_prompt_title: '',
        },
        data_point_dependencies: [],
        description: '',
        entry_actions: [],
        exit_actions: [],
        exit_page: {
            repeat_index: null,
            repeat_key: null,
            scene_key: '',
        },
        id: '',
        initial_pages: [],
        initial_post_submission_pages: [],
        post_submission_scenes: [],
        resolvers: {
            boolean_resolvers: [],
            date_resolvers: [],
            float_resolvers: [],
            integer_resolvers: [],
            range_resolvers: [],
            string_list_resolvers: [],
            string_resolvers: [],
            time_resolvers: [],
            timezone_resolvers: [],
            zoned_datetime_resolvers: [],
        },
        scenes: [],
        study_id: '',
        theme: {
            id: '',
            name: '',
            on_primary: '',
            on_primary_variant: '',
            on_secondary: '',
            primary: '',
            secondary: '',
        },
        title: '',
        version_group_id: '',
    },
};
export const initialUIState = {
    checked: false,
    loading: {
        message: '',
        progress: undefined,
        show: false,
    },
};
export const initialConfigState = {
    appConfig: {
        components: [],
        images: [],
        languages: [],
        navigation_destinations: [],
        styles: [],
        supports_phases: false,
        version: '',
        webSupport: false,
    },
};
export const initialAuthState = {
    currentRoute: {
        requireAuth: false,
        title: '',
    },
    currentUserPhase: '',
    currentUserPhaseEvent: {
        completed: false,
        event_date_time: '',
        id: '',
        meta: [],
        modifiable: false,
        name: '',
        original_offset_date: '',
        sort_key: null,
        tolerance_end: '',
        tolerance_start: '',
        type: APP_CONFIG.COMPONENTS.ScheduleEventType.QUESTIONNAIRE,
        window_end: '',
        window_start: '',
    },
    currentUserPhaseSegment: {
        id: '',
        originator: {
            id: '',
            type: '',
        },
    },
    identityProviders: {
        participant: [],
        professional: [],
    },
    schedule: [],
    segments: [],
    user: {
        data: {
            attributes: {
                change_reason: null,
                created_at: '',
                dcr_id: null,
                deleted_at: null,
                email: '',
                entered_by: '',
                first_name: '',
                group_id: '',
                last_name: '',
                local_datetime_registered: '',
                locale: '',
                subject_id: '',
                terms_agreed: false,
                tz_offset: '',
                updated_at: '',
            },
            id: '',
            links: {},
            type: UserType.PARTICIPANT,
        },
        meta: {
            actor: '',
            actor_type: UserType.PARTICIPANT,
        },
    },
    userInvitation: {
        code: '',
        id: '',
        links: {
            api_registration: '',
        },
        meta: {
            for: UserType.PARTICIPANT,
            locale: '',
            locales: [],
            type: 'invitation',
            valid: false,
        },
    },
};
