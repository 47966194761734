import { QuestActionType, } from '@/types';
export const addComputedValue = (payload) => ({
    payload,
    type: QuestActionType.ADD_COMPUTED_VALUE,
});
export const addPageAnswers = (payload) => ({
    payload,
    type: QuestActionType.ADD_PAGE_ANSWERS,
});
export const loadPartialSubmission = () => ({
    payload: undefined,
    type: QuestActionType.LOAD_PARTIAL_SUBMISSION,
});
export const resetPartialSubmission = () => ({
    payload: undefined,
    type: QuestActionType.RESET_PARTIAL_SUBMISSION,
});
export const resetQuestionnaire = () => ({
    payload: undefined,
    type: QuestActionType.RESET_QUESTIONNAIRE,
});
export const setCachedQueryAnswer = (payload) => ({
    payload,
    type: QuestActionType.SET_CACHED_QUERY_ANSWERS,
});
export const setQuestionnaire = (payload) => ({
    payload,
    type: QuestActionType.SET_QUESTIONNAIRE,
});
export const setFullState = (payload) => ({
    payload,
    type: QuestActionType.SET_FULL_STATE,
});
