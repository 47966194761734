var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/react';
import Lottie from 'react-lottie-player';
import logoAnimated from '@/assets/json/animated-logo-lottie.json';
import { DataTest } from '@/types';
export const Loading = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (_jsxs(Flex, Object.assign({ alignItems: "center", bg: "white.100", "data-test": DataTest.SPINNER, direction: "column", gridGap: "4", h: "100vh", justifyContent: "center", opacity: "0.85", pos: "fixed", w: "100vw", zIndex: "99" }, rest, { children: [_jsx(Lottie, { animationData: logoAnimated, loop: true, play: true, speed: 3, style: { width: 150, height: 150 } }), children] })));
};
