export var APP_CONFIG;
(function (APP_CONFIG) {
    let COMPONENTS;
    (function (COMPONENTS) {
        let ConfigFieldType;
        (function (ConfigFieldType) {
            ConfigFieldType["CHECKBOX"] = "checkbox_field";
            ConfigFieldType["MARKDOWN_HELP"] = "markdown_help_field";
            ConfigFieldType["MARKDOWN_TEXT"] = "markdown_text_field";
            ConfigFieldType["PASSWORD"] = "password_field";
            ConfigFieldType["TEXT"] = "text_field";
        })(ConfigFieldType = COMPONENTS.ConfigFieldType || (COMPONENTS.ConfigFieldType = {}));
        let ConfigWarnType;
        (function (ConfigWarnType) {
            ConfigWarnType["INFORMATION"] = "information";
            ConfigWarnType["SUCCESS"] = "success";
            ConfigWarnType["WARNING"] = "warning";
        })(ConfigWarnType = COMPONENTS.ConfigWarnType || (COMPONENTS.ConfigWarnType = {}));
        let ConfigBlockType;
        (function (ConfigBlockType) {
            ConfigBlockType["GRID"] = "grid";
            ConfigBlockType["HEADER"] = "header";
            ConfigBlockType["IMAGE"] = "image";
            ConfigBlockType["LIST"] = "list";
            ConfigBlockType["SCHEDULE"] = "schedule";
            // WARN TYPES
            ConfigBlockType["INFORMATION"] = "information";
            ConfigBlockType["SUCCESS"] = "success";
            ConfigBlockType["WARNING"] = "warning";
        })(ConfigBlockType = COMPONENTS.ConfigBlockType || (COMPONENTS.ConfigBlockType = {}));
        let ConfigScreenType;
        (function (ConfigScreenType) {
            ConfigScreenType["COVER"] = "cover_screen";
            ConfigScreenType["INPUT"] = "input_screen";
            ConfigScreenType["INSTRUCTIONS"] = "instructions_screen";
        })(ConfigScreenType = COMPONENTS.ConfigScreenType || (COMPONENTS.ConfigScreenType = {}));
        let ConfigComponentType;
        (function (ConfigComponentType) {
            ConfigComponentType["AD_HOC_QUESTIONNAIRE"] = "adhoc_questionnaire_component";
            ConfigComponentType["APP_ONBOARDING"] = "app_onboarding_component";
            ConfigComponentType["BODYMAP"] = "bodymap_component";
            ConfigComponentType["COMMON"] = "common_component";
            ConfigComponentType["CUSTOMISABLE_QUESTIONNAIRE_FEED"] = "customisable_questionnaire_feed_component";
            ConfigComponentType["DASHBOARD"] = "dashboard_component";
            ConfigComponentType["LANGUAGE_SELECTION"] = "language_selection_component";
            ConfigComponentType["LOGIN"] = "login_component";
            ConfigComponentType["MOTIF"] = "motif_component";
            ConfigComponentType["NATIVE_QUESTIONNAIRE"] = "native_questionnaire_component";
            ConfigComponentType["NAVIGATION"] = "navigation_component";
            ConfigComponentType["PASSWORD_FORGOT"] = "password_forgot_component";
            ConfigComponentType["PASSWORD_RESET"] = "password_reset_component";
            ConfigComponentType["PIN"] = "pin_component";
            ConfigComponentType["PREVIOUS_QUESTIONNAIRE_ENTRIES"] = "previous_questionnaire_entries_component";
            ConfigComponentType["QUESTIONNAIRE_FEED"] = "questionnaire_feed_component";
            ConfigComponentType["REGISTRATION"] = "registration_component";
            ConfigComponentType["SCHEDULE"] = "schedule_component";
            ConfigComponentType["SCHEDULE_QUERY"] = "schedule_query_component";
            ConfigComponentType["STUDY_UNSUPPORTED"] = "study_unsupported_component";
            ConfigComponentType["TELEHEALTH"] = "telehealth_component";
            ConfigComponentType["THIRD_PARTY_APP"] = "third_party_app_component";
            ConfigComponentType["WEB_DASHBOARD"] = "web_dashboard_component";
        })(ConfigComponentType = COMPONENTS.ConfigComponentType || (COMPONENTS.ConfigComponentType = {}));
        // =================================================================================================
        //  Navigation Component
        // =================================================================================================
        let NavigationComponentType;
        (function (NavigationComponentType) {
            NavigationComponentType["ADHOC_QUESTIONNAIRE"] = "adhoc_questionnaire";
            NavigationComponentType["ADHOC_QUESTIONNAIRE_HISTORY"] = "adhoc_questionnaire_history";
            NavigationComponentType["BODYMAP"] = "bodymap";
            NavigationComponentType["CATEGORY"] = "category";
            NavigationComponentType["DIALOG"] = "dialog";
            NavigationComponentType["MOTIF"] = "motif";
            NavigationComponentType["PREDEFINED"] = "predefined";
            NavigationComponentType["SIGN_OUT"] = "sign_out";
            NavigationComponentType["TELEHEALTH"] = "telehealth";
            NavigationComponentType["THIRD_PARTY_APP"] = "third_party_app";
            NavigationComponentType["WEB_VIEW"] = "web_view";
        })(NavigationComponentType = COMPONENTS.NavigationComponentType || (COMPONENTS.NavigationComponentType = {}));
        // =================================================================================================
        //  Schedule Query Component
        // =================================================================================================
        let ScheduleDataFilterValue;
        (function (ScheduleDataFilterValue) {
            ScheduleDataFilterValue["MATCH_BOTH_KEY_AND_VALUE"] = "MATCH_BOTH_KEY_AND_VALUE";
            ScheduleDataFilterValue["MATCH_KEY_EXISTS"] = "MATCH_KEY_EXISTS";
            ScheduleDataFilterValue["MATCH_KEY_EXISTS_BUT_VALUE_DIFFERENT"] = "MATCH_KEY_EXISTS_BUT_VALUE_DIFFERENT";
        })(ScheduleDataFilterValue = COMPONENTS.ScheduleDataFilterValue || (COMPONENTS.ScheduleDataFilterValue = {}));
        let ScheduleDataFilterType;
        (function (ScheduleDataFilterType) {
            ScheduleDataFilterType["AND"] = "AND";
            ScheduleDataFilterType["OR"] = "OR";
        })(ScheduleDataFilterType = COMPONENTS.ScheduleDataFilterType || (COMPONENTS.ScheduleDataFilterType = {}));
        let ScheduleEventType;
        (function (ScheduleEventType) {
            ScheduleEventType["AD_HOC_QUESTIONNAIRE"] = "AD_HOC_QUESTIONNAIRE";
            ScheduleEventType["MILESTONE"] = "MILESTONE";
            ScheduleEventType["QUESTIONNAIRE"] = "QUESTIONNAIRE";
            ScheduleEventType["VISIT"] = "VISIT";
            ScheduleEventType["LIGHT_REPORT"] = "LIGHT_REPORT";
        })(ScheduleEventType = COMPONENTS.ScheduleEventType || (COMPONENTS.ScheduleEventType = {}));
        let ScheduleSortType;
        (function (ScheduleSortType) {
            ScheduleSortType["BACKEND_SORT_KEY"] = "BACKEND_SORT_KEY";
            ScheduleSortType["LATEST"] = "LATEST";
            ScheduleSortType["OLDEST"] = "OLDEST";
        })(ScheduleSortType = COMPONENTS.ScheduleSortType || (COMPONENTS.ScheduleSortType = {}));
        let ScheduleDateType;
        (function (ScheduleDateType) {
            ScheduleDateType["END_OF_CALENDAR_MONTH"] = "END_OF_CALENDAR_MONTH";
            ScheduleDateType["END_OF_CALENDAR_WEEK"] = "END_OF_CALENDAR_WEEK";
            ScheduleDateType["END_OF_DAY"] = "END_OF_DAY";
            ScheduleDateType["END_OF_PHASE"] = "END_OF_PHASE";
            ScheduleDateType["ONE_MONTH_END_OF_DAY"] = "ONE_MONTH_END_OF_DAY";
            ScheduleDateType["ONE_WEEK_END_OF_DAY"] = "ONE_WEEK_END_OF_DAY";
            ScheduleDateType["ONE_YEAR_END_OF_DAY"] = "ONE_YEAR_END_OF_DAY";
            ScheduleDateType["START_OF_CALENDAR_MONTH"] = "START_OF_CALENDAR_MONTH";
            ScheduleDateType["START_OF_CALENDAR_WEEK"] = "START_OF_CALENDAR_WEEK";
            ScheduleDateType["START_OF_DAY"] = "START_OF_DAY";
            ScheduleDateType["START_OF_PHASE"] = "START_OF_PHASE";
        })(ScheduleDateType = COMPONENTS.ScheduleDateType || (COMPONENTS.ScheduleDateType = {}));
    })(COMPONENTS = APP_CONFIG.COMPONENTS || (APP_CONFIG.COMPONENTS = {}));
    let NAVIGATION;
    (function (NAVIGATION) {
        let NavigationType;
        (function (NavigationType) {
            NavigationType["APP_DESTINATION"] = "app_destination";
            NavigationType["WEB_DESTINATION"] = "web_destination";
        })(NavigationType = NAVIGATION.NavigationType || (NAVIGATION.NavigationType = {}));
        let NavigationDestinationType;
        (function (NavigationDestinationType) {
            NavigationDestinationType["BODYMAP"] = "BODYMAP";
            NavigationDestinationType["MOTIF"] = "MOTIF";
            NavigationDestinationType["PREVIOUS_QUESTIONNAIRE_ENTRIES"] = "PREVIOUS_QUESTIONNAIRE_ENTRIES";
            NavigationDestinationType["QUESTIONNAIRE_FEED"] = "QUESTIONNAIRE_FEED";
            NavigationDestinationType["SCHEDULE"] = "SCHEDULE";
            NavigationDestinationType["TELEHEALTH"] = "TELEHEALTH";
            NavigationDestinationType["THIRD_PARTY_APP"] = "THIRD_PARTY_APP";
        })(NavigationDestinationType || (NavigationDestinationType = {}));
    })(NAVIGATION = APP_CONFIG.NAVIGATION || (APP_CONFIG.NAVIGATION = {}));
})(APP_CONFIG || (APP_CONFIG = {}));
