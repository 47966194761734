import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, HStack, Slider, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import { ClearBtnBase, Icon } from '@/components';
import { useResolveValue } from '@/hooks';
import { ValuesType } from '@/types';
import { getInputBlockAnswer } from '@/utils';
export const VisualAnalogueScale = ({ block: { answer, clear_button_text, id, key, text_end, text_start }, defaultValue = null, onChange, }) => {
    const [resolver] = useResolveValue();
    const resolved = {
        label: resolver.resolveString(answer.label),
        textClearButton: resolver.resolveString(clear_button_text),
        textEnd: resolver.resolveString(text_end),
        textStart: resolver.resolveString(text_start),
    };
    const hasValue = defaultValue !== null;
    const updateAnswer = (newValue) => {
        const answerValue = Object.assign(Object.assign({}, answer), { label: resolved.label, value: newValue });
        const newAnswer = getInputBlockAnswer(ValuesType.FLOAT, answerValue, key);
        onChange(newAnswer);
    };
    const handleChange = (newValue) => updateAnswer(newValue);
    const handleDeselect = () => updateAnswer(null);
    return (_jsxs(Flex, Object.assign({ "data-test": id, direction: "column", gridGap: "3", marginX: { base: '20px', lg: 0 }, marginInlineEnd: 0, marginInlineStart: 0 }, { children: [_jsxs(Slider, Object.assign({ defaultValue: defaultValue, max: 1, min: 0, onChangeEnd: handleChange, step: 0.0000001, sx: {
                    p: '0 !important',
                    '&::before, &::after': {
                        content: '""',
                        position: 'absolute',
                        top: '-5px',
                        w: '2px',
                        h: '12px',
                        bg: 'darkBrandBlue.10',
                    },
                    '&::after': {
                        right: '0',
                    },
                } }, { children: [_jsx(SliderTrack, { _before: {
                            content: '""',
                            position: 'absolute',
                            top: '20px',
                            w: '100%',
                            h: '2px',
                            bg: 'darkBrandBlue.10',
                        }, bg: "transparent", h: "40px" }), hasValue && (_jsx(SliderThumb, Object.assign({ _focus: {
                            boxShadow: '0 0 0 10px rgba(0,0,0, 0.06)',
                        }, bg: "darkBrandBlue.40", boxSize: "40px" }, { children: _jsx(Icon, { color: "darkBrandBlue.80", name: "chevron-drag" }) })))] })), _jsxs(HStack, Object.assign({ alignItems: "flex-end", fontSize: "sm", h: "40px", justifyContent: "space-between", position: "relative", spacing: "2" }, { children: [_jsx(Text, { children: resolved.textStart }), hasValue && (_jsx(ClearBtnBase, Object.assign({ onClick: handleDeselect, position: "absolute", right: "50%", top: "55px", transform: "translate(50%, -50%)" }, { children: resolved.textClearButton }))), _jsx(Text, { children: resolved.textEnd })] }))] }), defaultValue));
};
