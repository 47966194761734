var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MarkdownBase } from '@/components';
import { SchedulingService } from '@/services';
import { closeLoading, setCurrentRoute, showLoading, useAuth, useUI } from '@/store';
import { DataTest, ROUTE, UrlParamsKey } from '@/types';
import { Box, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { generatePath, useParams, useSearchParams } from 'react-router-dom';
const LightReport = () => {
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const uiCtx = useUI();
    const { dispatch: authDispatch, state: { currentRoute }, } = useAuth();
    const [reportDetail, setReportDetail] = useState({
        report: { content: '', header: '' },
        title: '',
    });
    const [reportList, setReportList] = useState({
        empty_message: '',
        groups: [],
        pagination: { current_page: 1, total_pages: 1 },
        title: '',
    });
    const reportItemId = searchParams.get(UrlParamsKey.REPORT_ITEM_ID);
    const reportItemType = searchParams.get(UrlParamsKey.REPORT_ITEM_TYPE);
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                uiCtx.dispatch(showLoading());
                yield Promise.all([
                    (() => __awaiter(void 0, void 0, void 0, function* () {
                        const lightReportDetail = yield SchedulingService.getLightReportDetail({
                            eventId: id,
                            options: {
                                report_item_id: reportItemId,
                                report_item_type: reportItemType,
                            },
                        });
                        setReportDetail(lightReportDetail);
                    }))(),
                    (() => __awaiter(void 0, void 0, void 0, function* () {
                        const lightReportList = yield SchedulingService.getLightReportList(id, 1);
                        setReportList(lightReportList);
                    }))(),
                ]);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                uiCtx.dispatch(closeLoading());
            }
        });
        fetchData();
    }, []);
    useEffect(() => {
        var _a;
        if (!reportList.title || !reportDetail.title)
            return;
        const url = generatePath(ROUTE.LIGHT_REPORT_LIST, { id });
        const parentRoutes = [
            {
                path: url,
                title: reportList.title,
            },
        ];
        const updatedRoute = Object.assign(Object.assign({}, currentRoute), { settings: Object.assign(Object.assign({}, currentRoute.settings), { breadcrumb: Object.assign(Object.assign({}, (_a = currentRoute.settings) === null || _a === void 0 ? void 0 : _a.breadcrumb), { parents: parentRoutes }) }), title: reportDetail.title });
        authDispatch(setCurrentRoute(updatedRoute));
    }, [reportDetail.title, reportList.title, currentRoute.title]);
    return (_jsxs(VStack, Object.assign({ "data-test": DataTest.LIGHT_REPORT_DETAIL, spacing: 6, w: "100%" }, { children: [_jsx(Box, Object.assign({ bg: "grey.60", p: "4", w: "100%" }, { children: _jsx(MarkdownBase, { markdown: reportDetail.report.header, maxW: "820px", mx: "auto" }) })), _jsx(Box, Object.assign({ px: "4", w: "100%" }, { children: _jsx(MarkdownBase, { markdown: reportDetail.report.content, maxW: "820px", mx: "auto" }) }))] })));
};
export default LightReport;
