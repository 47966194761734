import { APP_CONFIG } from '@/types';
const { ScheduleEventType } = APP_CONFIG.COMPONENTS;
export var UserPhaseEventMetaType;
(function (UserPhaseEventMetaType) {
    UserPhaseEventMetaType["BACKGROUND_COLOUR"] = "background_colour";
    UserPhaseEventMetaType["DESCRIPTION"] = "description";
    UserPhaseEventMetaType["ICON"] = "icon_path";
    UserPhaseEventMetaType["LONG_TITLE"] = "long_title";
    UserPhaseEventMetaType["NAME"] = "name";
    UserPhaseEventMetaType["QUESTIONNAIRE_ID"] = "questionnaire_id";
    UserPhaseEventMetaType["SHORT_TITLE"] = "short_title";
    UserPhaseEventMetaType["SUBHEADING_1"] = "subheading1";
    UserPhaseEventMetaType["SUBHEADING_2"] = "subheading2";
    UserPhaseEventMetaType["TEXT_COLOUR"] = "text_colour";
    UserPhaseEventMetaType["LIGHT_REPORT_LIST"] = "light_report_list";
    UserPhaseEventMetaType["LIGHT_REPORT_MODE"] = "light_report_mode";
    UserPhaseEventMetaType["LIGHT_REPORT_DETAIL_DEFAULT"] = "light_report_detail_default";
})(UserPhaseEventMetaType || (UserPhaseEventMetaType = {}));
export var LightReportMetaValue;
(function (LightReportMetaValue) {
    LightReportMetaValue["LIST"] = "list";
})(LightReportMetaValue || (LightReportMetaValue = {}));
export const UserPhaseEventMapping = Object.freeze(Object.fromEntries(Object.entries(ScheduleEventType).map(([key, value]) => [key, value.toLowerCase()])));
