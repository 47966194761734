export var QuestActionType;
(function (QuestActionType) {
    QuestActionType["ADD_COMPUTED_VALUE"] = "ADD_COMPUTED_VALUE";
    QuestActionType["ADD_PAGE_ANSWERS"] = "ADD_PAGE_ANSWERS";
    QuestActionType["LOAD_PARTIAL_SUBMISSION"] = "LOAD_PARTIAL_SUBMISSION";
    QuestActionType["RESET_PARTIAL_SUBMISSION"] = "RESET_PARTIAL_SUBMISSION";
    QuestActionType["RESET_QUESTIONNAIRE"] = "RESET_QUESTIONNAIRE";
    QuestActionType["SET_CACHED_QUERY_ANSWERS"] = "SET_CACHED_QUERY_ANSWERS";
    QuestActionType["SET_QUESTIONNAIRE"] = "SET_QUESTIONNAIRE";
    QuestActionType["SET_FULL_STATE"] = "SET_FULL_STATE";
})(QuestActionType || (QuestActionType = {}));
