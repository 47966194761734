export var ROUTE;
(function (ROUTE) {
    // Lens Routes
    ROUTE["LENS_QUESTIONNAIRE"] = "/lens/clinro/:id";
    ROUTE["LENS_QUESTIONNAIRE_SUBMITTED"] = "/lens/clinro/submitted";
    // Questionnaire Builder Routes
    ROUTE["QUESTIONNAIRE_BUILDER_PREVIEW"] = "/qb/preview";
    // Authenticated Routes
    ROUTE["DASHBOARD"] = "/dashboard";
    ROUTE["HELP"] = "/help";
    ROUTE["PROFILE"] = "/profile";
    ROUTE["QUESTIONNAIRE"] = "/questionnaire/:id";
    ROUTE["LIGHT_REPORT_LIST"] = "/report/:id";
    ROUTE["LIGHT_REPORT_DETAIL"] = "/report/:id/detail";
    ROUTE["SCREEN"] = "/screen/:id";
    // Unauthenticated Routes
    ROUTE["HOME"] = "/";
    ROUTE["CONTACT_SITE"] = "/contact-site";
    ROUTE["IDENTITY_PROVIDER"] = "/identity-provider";
    ROUTE["LANGUAGE"] = "/language";
    ROUTE["LOGIN"] = "/login";
    ROUTE["NOT_FOUND"] = "/404";
    ROUTE["PASSWORD_FORGOT"] = "/forgot-password";
    ROUTE["PASSWORD_RESET"] = "/reset-password/:token/:identifier";
    ROUTE["REGISTER"] = "/register";
    ROUTE["UNSUPPORTED"] = "/unsupported";
    // External Routes
    ROUTE["PRIVACY_POLICY"] = "https://umotif.com/privacypolicy";
    ROUTE["TERMS_AND_CONDITIONS"] = "https://umotif.com/termsandconditions";
})(ROUTE || (ROUTE = {}));
