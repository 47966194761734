import { jsx as _jsx } from "react/jsx-runtime";
import { ChakraProvider } from '@chakra-ui/react';
import Compose from 'react-compose-components';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './config/localization';
import { AxiosInterceptor } from './services/instance';
import { AuthProvider, ConfigProvider, QuestionnaireProvider, UIProvider } from './store';
import theme from './styles/theme';
// Log the app version to the console for convenience
console.info(`App version: ${process.env.VERSION}`);
ReactDOM.render(_jsx(Compose, Object.assign({ components: [
        BrowserRouter,
        [ChakraProvider, { theme, resetCSS: true }],
        UIProvider,
        QuestionnaireProvider,
        ConfigProvider,
        AuthProvider,
        AxiosInterceptor,
    ] }, { children: _jsx(App, {}) })), document.getElementById('app'));
