var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider, Table, Td, Th, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button, GenericError, MarkdownBase, PageWrapper } from '@/components';
import studyExperienceService from '@/services/studyExperience.service';
import { closeLoading, showLoading, useAuth, useUI } from '@/store';
import { DataTest } from '@/types';
import { useEffect, useState } from 'react';
export const Profile = () => {
    const { t } = useTranslation();
    const uiCtx = useUI();
    const { handleLogout, state: { user }, } = useAuth();
    const [profile, setProfile] = useState(null);
    const [hasError, setHasError] = useState(false);
    function fetchProfileData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                uiCtx.dispatch(showLoading());
                const { data, error } = yield studyExperienceService.getProfile();
                setHasError(!!error);
                setProfile(data);
            }
            catch (e) {
                setHasError(true);
            }
            finally {
                uiCtx.dispatch(closeLoading());
            }
        });
    }
    useEffect(() => {
        fetchProfileData();
    }, [user.data.attributes.email]);
    if (hasError)
        return _jsx(GenericError, {});
    if (!profile)
        return null;
    return (_jsx(PageWrapper, Object.assign({ gridGap: 8, pb: "0", pt: "16", sx: { '& > h2': { marginBottom: 0 } }, title: t('app:components.navigation.profile') }, { children: _jsxs(_Fragment, { children: [profile.content && (_jsx(MarkdownBase, { color: "darkBrandBlue.90", markdown: profile.content })), _jsx(Table, Object.assign({ border: "1px solid", borderColor: "darkBrandBlue.5", style: { tableLayout: 'fixed' }, w: ['100%', '100%', '100%', '50%'] }, { children: profile.profile_information.map((row) => (_jsxs(Tr, { children: [_jsx(Th, Object.assign({ backgroundColor: "#f6f9fc", border: "1px solid var(--chakra-colors-darkBrandBlue-5)", color: "darkBrandBlue.90", textAlign: "center", textTransform: "none" }, { children: row.label })), _jsx(Td, Object.assign({ textAlign: "center" }, { children: row.value }))] }))) })), _jsx(Divider, { bg: "grey.80", h: "1px", my: "12", opacity: "100%" }), _jsx(Button, Object.assign({ "data-test": DataTest.PROFILE_LOGOUT, onClick: handleLogout, variant: "cancel", w: "fit-content" }, { children: t('app:components.common.logout') }))] }) })));
};
