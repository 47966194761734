import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '@/components';
import { DataTest } from '@/types';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
export const GenericError = () => {
    const { t } = useTranslation();
    const resolved = {
        errorText: t('common:message.error.default'),
        retryLabel: t('app:components.common.retry'),
    };
    return (_jsxs(Flex, Object.assign({ alignItems: "center", "data-test": DataTest.QUESTIONNAIRE_ERROR_OCCURRED, direction: "column", gridGap: "3", h: "inherit", justifyContent: "center" }, { children: [_jsx(Icon, { color: "red.500", name: "exclamation-circle", size: "35", type: "solid" }), _jsx(Text, Object.assign({ fontSize: "md" }, { children: resolved.errorText })), _jsx(Button, Object.assign({ mt: "8", onClick: () => window.location.reload() }, { children: resolved.retryLabel }))] })));
};
