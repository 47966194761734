var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UMOTIF_API } from '@/routes';
import { createQueryParams } from '@/utils';
import api from './instance';
const { SCHEDULING } = UMOTIF_API;
class SchedulingService {
    getCurrentUserPhaseAndEvents(query) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = ''; // query ? `?${getQueryParams(query)}` : '';
            const { data } = yield api.get(SCHEDULING.CURRENT_USER_PHASE_EVENTS + params);
            return data;
        });
    }
    getLightReportDetail(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                report_item_id: payload.options.report_item_id,
                report_item_type: payload.options.report_item_type,
            };
            const url = `${SCHEDULING.LIGHT_REPORT_DETAIL(payload.eventId)}${createQueryParams(params)}`;
            const { data } = yield api.get(url);
            return data;
        });
    }
    getLightReportList(eventId, page) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                'pagination[page]': page,
            };
            const url = `${SCHEDULING.LIGHT_REPORT_LIST(eventId)}${createQueryParams(params)}`;
            const { data } = yield api.get(url);
            return data;
        });
    }
}
export default new SchedulingService();
