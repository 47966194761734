import { isClinro } from '@/config';
const apiPrefix = isClinro ? 'web' : 'api';
const baseAuth = '/api/auth/';
const baseIdentity = '/api/services/identity/v1/auth/';
const baseSchedule = '/api/services/scheduling/v1/';
const baseCapture = `/${apiPrefix}/services/data-capture/v1/`;
const baseStudyExperience = '/api/services/study-experience';
export const UMOTIF_API = {
    AUTH: {
        LOGIN: `${baseAuth}token`,
        ME: `${baseAuth}participant/me`,
        PASSWORD_FORGOT: `${baseAuth}forgotten-password`,
        PASSWORD_RESET: `${baseAuth}password/reset`,
        REFRESH_TOKEN: `${baseAuth}token`,
        REGISTER: `${baseAuth}participant/register`,
        VALIDATE_INVITE_CODE: (code) => `${baseAuth}invitation/${code}/check`,
    },
    CONFIG: {
        GET_APP_CONFIG: '/configuration/app',
    },
    DATA_CAPTURE: {
        CACHED_QUERY_MANY: `${baseCapture}cached-query/custom/execute/many`,
        CACHED_QUERY_SINGLE: `${baseCapture}cached-query/custom/execute/single`,
        GET_LATEST_SOURCED_DATA: `${baseCapture}latest-sourced-data`,
        GET_QUESTIONNAIRE: `${baseCapture}get-configurations`,
        POST_OVERLAP: `${baseCapture}overlap`,
        POST_PARTIALS: `${baseCapture}post-partials`,
        POST_START_UPLOAD: `${baseCapture}start-upload`,
        POST_SUBMISSIONS: `${baseCapture}post-submissions`,
        GET_PARTIAL: (id) => `${baseCapture}get-partials/${id}`,
    },
    IDENTITY: {
        GET_TOKEN: `${baseIdentity}exchange-code-for-token`,
        IDPS: `${baseIdentity}idps`,
    },
    SCHEDULING: {
        CURRENT_USER_PHASE_EVENTS: `${baseSchedule}current-user-phase-and-events`,
        LIGHT_REPORT_DETAIL: (eventId) => `${baseSchedule}events/${eventId}/light-report/detail`,
        LIGHT_REPORT_LIST: (eventId) => `${baseSchedule}events/${eventId}/light-report/list`,
    },
    STUDY_EXPERIENCE: {
        PROFILE: `${baseStudyExperience}/participant/profile`,
    },
};
export default UMOTIF_API;
