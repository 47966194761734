var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox as ChakraCheckbox, Flex } from '@chakra-ui/react';
import { MarkdownBase } from './MarkdownBase';
export const Checkbox = (_a) => {
    var { answer, answerChange, isDisabled } = _a, rest = __rest(_a, ["answer", "answerChange", "isDisabled"]);
    const onChange = (e) => {
        const newAnswer = !e.target.checked ? Object.assign(Object.assign({}, answer), { value: null }) : answer;
        answerChange(newAnswer);
    };
    const markdownOpacity = isDisabled ? '0.5' : 1;
    return (_jsxs(Flex, Object.assign({ alignItems: "center", borderBottom: "1px", borderColor: "darkBrandBlue.10", gridGap: "8", opacity: markdownOpacity, pb: "10px" }, { children: [_jsx(MarkdownBase, { "data-test": answer.id, flex: "1", markdown: answer.label }), _createElement(ChakraCheckbox, Object.assign({}, rest, { "aria-label": answer.label, isDisabled: isDisabled, key: answer.id, ml: "auto", mr: "0", onChange: onChange, variant: "questionnaire" }))] })));
};
