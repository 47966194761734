export var PaginationType;
(function (PaginationType) {
    PaginationType["PAGE"] = "page";
})(PaginationType || (PaginationType = {}));
export var PagingBehaviourType;
(function (PagingBehaviourType) {
    PagingBehaviourType["PAGER"] = "pager";
})(PagingBehaviourType || (PagingBehaviourType = {}));
export var ListItemType;
(function (ListItemType) {
    ListItemType["TWO_SECTION_CARD"] = "2-section-card";
})(ListItemType || (ListItemType = {}));
export var ReportType;
(function (ReportType) {
    ReportType["MARKDOWN"] = "markdown";
})(ReportType || (ReportType = {}));
