import { v4 as uuid_v4 } from 'uuid';
import { initialQuestionnaireState } from '@/store';
import { QuestActionType } from '@/types';
export const questionnaireReducer = (state, action) => {
    var _a, _b, _c;
    switch (action.type) {
        case QuestActionType.RESET_QUESTIONNAIRE: {
            return initialQuestionnaireState;
        }
        case QuestActionType.RESET_PARTIAL_SUBMISSION: {
            return Object.assign(Object.assign({}, state), { partialSubmission: null });
        }
        case QuestActionType.SET_FULL_STATE: {
            return Object.assign({}, state);
        }
        case QuestActionType.LOAD_PARTIAL_SUBMISSION: {
            const answeredAnswers = ((_a = state.partialSubmission) === null || _a === void 0 ? void 0 : _a.partial.answers.filter((answer) => answer.answered)) || [];
            return Object.assign(Object.assign({}, state), { current: Object.assign(Object.assign({}, state.current), { state: Object.assign(Object.assign({}, state.current.state), { answers: answeredAnswers, exit_action_queue_history: ((_b = state.partialSubmission) === null || _b === void 0 ? void 0 : _b.partial.state.exit_action_queue_history) || [], journey: ((_c = state.partialSubmission) === null || _c === void 0 ? void 0 : _c.partial.state.journey) || [] }) }) });
        }
        case QuestActionType.SET_CACHED_QUERY_ANSWERS: {
            const { payload } = action;
            return Object.assign(Object.assign({}, state), { cachedQueryAnswers: payload });
        }
        case QuestActionType.SET_QUESTIONNAIRE: {
            const { partialSubmission, questionnaire } = action.payload;
            const id = uuid_v4();
            return Object.assign(Object.assign({}, state), { current: Object.assign(Object.assign({}, state.current), { answers: [], id, original_submission_id: id, questionnaire_id: questionnaire.id, state: {
                        answers: [],
                        exit_action_queue_history: [],
                        journey: [...questionnaire.initial_pages, questionnaire.exit_page],
                    } }), partialSubmission, questionnaire: Object.assign(Object.assign({}, state.questionnaire), questionnaire) });
        }
        case QuestActionType.ADD_COMPUTED_VALUE: {
            const { payload } = action;
            const computedValues = [
                ...state.current.computed_values.filter((cv) => !payload.some((pl) => {
                    return (pl.computed_value_id === cv.computed_value_id &&
                        pl.page_key.repeat_key === cv.page_key.repeat_key &&
                        pl.page_key.repeat_index === cv.page_key.repeat_index);
                })),
                ...payload,
            ];
            return Object.assign(Object.assign({}, state), { current: Object.assign(Object.assign({}, state.current), { computed_values: computedValues }) });
        }
        case QuestActionType.ADD_PAGE_ANSWERS: {
            const { payload: { newAnswer, options: { multipleAnswer = false, order = 0, repeatIndex = null, repeatKey = null, sceneKey = '', }, removedAnswers, }, } = action;
            // Make all answers exist as Array
            const answersArr = Array.isArray(newAnswer) ? newAnswer : [newAnswer];
            // Remove if at least one answer has a null value
            const hasNullValue = answersArr.some((item) => item.typed_value.value === null);
            // Add page_key to answers
            const answersList = answersArr.map((item) => {
                return Object.assign(Object.assign({}, item), { meta: {
                        order,
                    }, page_key: {
                        repeat_index: repeatIndex,
                        repeat_key: repeatKey,
                        scene_key: sceneKey,
                    } });
            });
            const answers = hasNullValue
                ? state.current.state.answers.filter((a) => {
                    return !answersList.some((b) => b.answer_id === a.answer_id);
                })
                : state.current.state.answers
                    .filter((a) => {
                    // True if answer exists in answersList
                    const exists = answersList.some((b) => b.typed_value.value === a.typed_value.value &&
                        b.block_key === a.block_key &&
                        b.page_key.scene_key === a.page_key.scene_key &&
                        b.page_key.repeat_key === a.page_key.repeat_key &&
                        b.page_key.repeat_index === a.page_key.repeat_index);
                    const isSingleAnswer = !multipleAnswer &&
                        answersList.some((b) => b.block_key === a.block_key &&
                            b.page_key.scene_key === a.page_key.scene_key &&
                            b.page_key.repeat_key === a.page_key.repeat_key &&
                            b.page_key.repeat_index === a.page_key.repeat_index);
                    return !exists && !isSingleAnswer;
                })
                    .concat(answersList);
            // Remove answers that were removed by the dependencies
            const finalsAnswers = answers.filter((a) => {
                return !removedAnswers.some((b) => b.block_key === a.block_key &&
                    b.page_key.scene_key === a.page_key.scene_key &&
                    b.page_key.repeat_key === a.page_key.repeat_key &&
                    b.page_key.repeat_index === a.page_key.repeat_index);
            });
            return Object.assign(Object.assign({}, state), { current: Object.assign(Object.assign({}, state.current), { state: Object.assign(Object.assign({}, state.current.state), { answers: finalsAnswers }) }) });
        }
        default:
            throw new Error();
    }
};
