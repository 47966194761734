import { jsx as _jsx } from "react/jsx-runtime";
import i18n from 'i18next';
import { Navigate } from 'react-router-dom';
import { CodeEntry, ContactSite, Dashboard, Help, Identity, LanguageSelection, LensSubmitted, Login, NotFound, PasswordForgot, PasswordReset, Profile, Questionnaire, Register, Screen, Unsupported, } from '@/pages';
import LightReport from '@/pages/LightReport';
import LightReportDetail from '@/pages/LightReportDetail';
import { ROUTE } from '@/types';
const generateRoutesFromConfig = (config) => {
    return config.map((route) => (Object.assign(Object.assign({ requireAuth: false, title: '' }, route), { settings: Object.assign({ breadcrumb: {
                display: false,
            }, footer: {
                display: true,
            }, header: {
                display: true,
            } }, route === null || route === void 0 ? void 0 : route.settings) })));
};
const routesConfig = {
    authenticated: [
        {
            element: _jsx(Dashboard, {}),
            path: ROUTE.DASHBOARD,
            requireAuth: true,
            title: i18n.t('app:components.navigation.dashboard'),
        },
        {
            element: _jsx(Help, {}),
            path: ROUTE.HELP,
            requireAuth: true,
            settings: {
                breadcrumb: {
                    display: true,
                },
            },
            title: i18n.t('app:components.navigation.help_title'),
        },
        {
            element: _jsx(Profile, {}),
            path: ROUTE.PROFILE,
            requireAuth: true,
            settings: {
                breadcrumb: {
                    display: true,
                },
            },
            title: i18n.t('app:components.navigation.profile'),
        },
        {
            element: _jsx(Questionnaire, {}),
            path: ROUTE.QUESTIONNAIRE,
            requireAuth: true,
            settings: {
                breadcrumb: {
                    display: true,
                },
                footer: {
                    display: false,
                },
            },
        },
        {
            element: _jsx(Screen, {}),
            path: ROUTE.SCREEN,
            requireAuth: true,
            settings: {
                breadcrumb: {
                    display: true,
                },
            },
        },
        {
            element: _jsx(LightReport, {}),
            path: ROUTE.LIGHT_REPORT_LIST,
            requireAuth: true,
            settings: {
                breadcrumb: {
                    display: true,
                },
                footer: {
                    display: false,
                },
            },
        },
        {
            element: _jsx(LightReportDetail, {}),
            path: ROUTE.LIGHT_REPORT_DETAIL,
            requireAuth: true,
            settings: {
                breadcrumb: {
                    display: true,
                },
                footer: {
                    display: false,
                },
            },
        },
    ],
    clinro: [
        {
            element: _jsx(Questionnaire, {}),
            path: ROUTE.LENS_QUESTIONNAIRE,
        },
        {
            element: _jsx(LensSubmitted, {}),
            path: ROUTE.LENS_QUESTIONNAIRE_SUBMITTED,
        },
    ],
    questionnaire_builder: [
        {
            element: _jsx(Questionnaire, {}),
            path: ROUTE.QUESTIONNAIRE_BUILDER_PREVIEW,
            settings: {
                breadcrumb: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                header: {
                    display: false,
                },
            },
        },
    ],
    unauthenticated: [
        {
            element: _jsx(Identity, {}),
            path: ROUTE.IDENTITY_PROVIDER,
        },
        {
            element: _jsx(CodeEntry, {}),
            path: ROUTE.HOME,
        },
        {
            element: _jsx(Register, {}),
            path: ROUTE.REGISTER,
        },
        {
            element: _jsx(LanguageSelection, {}),
            path: ROUTE.LANGUAGE,
        },
        {
            element: _jsx(Login, {}),
            path: ROUTE.LOGIN,
        },
        {
            element: _jsx(PasswordForgot, {}),
            path: ROUTE.PASSWORD_FORGOT,
        },
        {
            element: _jsx(PasswordReset, {}),
            path: ROUTE.PASSWORD_RESET,
        },
        {
            element: _jsx(Unsupported, {}),
            path: ROUTE.UNSUPPORTED,
        },
        {
            element: _jsx(ContactSite, {}),
            path: ROUTE.CONTACT_SITE,
        },
        {
            element: _jsx(NotFound, {}),
            path: ROUTE.NOT_FOUND,
        },
    ],
};
export const authRoutes = routesConfig.authenticated.map((route) => route.path.toString());
export const publicRoutes = routesConfig.unauthenticated.map((route) => route.path.toString());
export const routes = [
    ...generateRoutesFromConfig(Object.values(routesConfig).flat()),
    {
        element: _jsx(Navigate, { to: ROUTE.NOT_FOUND }),
        path: '*',
    },
];
